import ria from "../images/ria/Ria.png";

export default function RiaBanner() {
  return (
    <div class="flex items-center gap-2 md:gap-4 max-md:flex-row bg-gradient-to-r from-[#FF9655] via-white to-[#FF9655] text-black px-6 py-1.5  font-[sans-serif] justify-center">
      <div className="h-4 md:h-6">
        <p class=" text-sm md:text-base  max-md:text-center align-bottom tracking-wider">
          We now have
        </p>
      </div>

      <div className=" md:pb-1.5">
        <img src={ria} className="h-4 md:h-6" />
      </div>
    </div>
  );
}
