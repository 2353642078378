import emailjs from "emailjs-com";
import FormDialog from "../components/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const SERVICE_ID = "service_pats2663";
const TEMPLATE_ID = "template_y574abq";
const USER_ID = "L-RS3Iy7MsjHFyIzh";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const resetForm = () => {
    setFormData({ name: "", email: "", message: "" });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = (e) => {
    console.log(formData);
    e.preventDefault();
    emailjs.sendForm(
      SERVICE_ID,
      TEMPLATE_ID,
      e.target,

      USER_ID
    );
    resetForm();
  };

  return (
    <div className="flex flex-row min-h-full justify-center items-start m-8">
      <div className="mx-auto w-full max-w-xl content-center">
        <h1 className="text-center text-3xl font-bold">Contact Us</h1>

        <form id="contact-form" onSubmit={handleOnSubmit}>
          <div className="mb-5">
            <label for="name" className=" block text-base font-medium">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your name"
              className="w-full rounded-md border py-3 px-4 text-base"
            />
          </div>
          <div className="mb-5">
            <label for="email" className=" block text-base font-medium">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your email"
              className="w-full rounded-md border py-3 px-4 text-base "
            />
          </div>
          <div className="mb-5">
            <label
              for="message"
              className="block mb-[10.5px] text-base font-medium"
            >
              Message
            </label>
            <textarea
              rows="4"
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message..."
              className="w-full resize-none py-3 px-4 rounded-md border text-base"
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-Eucalyptus px-3 py-1.5 text-sm font-semibold leading-6 text-white  hover:bg-Eucalyptus-dark hover:cursor-auto"
            >
              <FormDialog />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default ContactUs;

const Modal = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        className="bg-Eucalyptus text-white mr-1 mb-1"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Submit
      </button>
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-50 outline-none focus:outline-none  border-gray-400/30 ">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <button
                    className="relative bg-transparent border-0 text-black float-right"
                    type="submit"
                    onClick={() => setShowModal(false)}
                  >
                    <FontAwesomeIcon icon={faXmarkCircle} size="lg" />
                  </button>
                  <p className="mx-3 text-xl text-wrap">
                    Thank you for your message. We will get back to you shortly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
