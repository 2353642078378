import { Dialog, DialogPanel, DialogTitle, Button } from "@headlessui/react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

export default function FormDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };
  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md hover:cursor-auto bg-transparent shadow-none hover:cursor-pointer"
      >
        Submit
      </button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 backdrop-blur-[2px]	">
          <DialogPanel className="max-w-lg space-y-4 border bg-white p-10">
            <Button
              className="relative items-center gap-2 float-right"
              onClick={close}
            >
              <FontAwesomeIcon icon={faXmarkCircle} size="lg" />
            </Button>
            <DialogTitle className="font-bold">Message sent</DialogTitle>
            <p className="mx-3 text-xl text-wrap">
              Thank you for your message. We will get back to you shortly.
            </p>
            <div className="mt-4"></div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
