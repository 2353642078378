//import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";

import logo from "../images/BarnLogo.png";

const links = [
  { name: "Home", link: "/", current: false },
  // { name: "Sales", link: "/", current: false },
  { name: "Contact Us", link: "/contact", current: false },
  { name: "Return Policy", link: "/returns", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav() {
  return (
    <Disclosure as="nav" className="bg-Westar drop-shadow-md ">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-Eucalyptus  hover:text-Eucalyptus-dark cursor-pointer">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="block h-6 w-6 "
                      aria-hidden="true"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBars}
                      className="block h-6 w-6 cursor-pointer"
                      aria-hidden="true"
                    />
                  )}
                </DisclosureButton>
              </div>
              {/* DESKTOP */}
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <LogoLink />
                </div>
                <div className="hidden sm:ml-6 sm:block grow">
                  <div className="flex space-x-4 justify-end">
                    <NavLink
                      key="Home"
                      to="/"
                      className={({ isActive }) =>
                        [
                          isActive
                            ? "text-Eucalyptus  underline underline-offset-4 decoration-[1.5px] font-semibold"
                            : "text-Eucalyptus-dark  hover:underline hover:underline-offset-4  hover:decoration-[1.5px]",
                          " px-3 py-2 text-base font-normal font-Montserrat",
                        ].join(" ")
                      }
                    >
                      Home
                    </NavLink>
                    <NavLink
                      key="Contact"
                      to="/contact"
                      className={({ isActive }) =>
                        [
                          isActive
                            ? "text-Eucalyptus  underline underline-offset-4 decoration-[1.5px] font-semibold"
                            : "text-Eucalyptus-dark  hover:underline hover:underline-offset-4  hover:decoration-[1.5px]",
                          " px-3 py-2 text-base font-normal font-Montserrat",
                        ].join(" ")
                      }
                    >
                      Contact Us
                    </NavLink>
                    <NavLink
                      key="Return"
                      to="/returns"
                      className={({ isActive }) =>
                        [
                          isActive
                            ? "text-Eucalyptus  underline underline-offset-4 decoration-[1.5px] font-semibold"
                            : "text-Eucalyptus-dark  hover:underline hover:underline-offset-4  hover:decoration-[1.5px]",
                          " px-3 py-2 text-base font-normal font-Montserrat",
                        ].join(" ")
                      }
                    >
                      Return Policy
                    </NavLink>
                    {/* {links.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.link}
                        className={({ isActive }) =>
                          [
                            isActive
                              ? "text-Eucalyptus  underline underline-offset-4 decoration-[1.5px] font-semibold"
                              : "text-Eucalyptus-dark  hover:underline hover:underline-offset-4  hover:decoration-[1.5px]",
                            " px-3 py-2 text-base font-normal font-Montserrat",
                          ].join(" ")
                        }
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))} */}
                    <a
                      href="/flyer.pdf"
                      target="_blank "
                      rel="noreferrer"
                      className="text-Eucalyptus-dark font-Montserrat hover:underline hover:underline-offset-4  hover:decoration-[1.5px] px-3 py-2 text-base font-normal"
                    >
                      Sales
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* MOBILE */}
          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              <DisclosureButton
                key="Home"
                as={NavLink}
                to="/"
                className="block rounded-md px-3 py-2 text-base hover:bg-Westar-dark text-Eucalyptus hover:text-Eucalyptus-dark font-medium hover:font-semibold"
              >
                Home
              </DisclosureButton>
              <DisclosureButton
                key="Contact"
                as={NavLink}
                to="/contact"
                className="block rounded-md px-3 py-2 text-base hover:bg-Westar-dark text-Eucalyptus hover:text-Eucalyptus-dark font-medium hover:font-semibold"
              >
                Contact Us
              </DisclosureButton>
              <DisclosureButton
                key="Return"
                as={NavLink}
                to="/returns"
                className="block rounded-md px-3 py-2 text-base hover:bg-Westar-dark text-Eucalyptus hover:text-Eucalyptus-dark font-medium hover:font-semibold"
              >
                Return Policy
              </DisclosureButton>
              <DisclosureButton
                as="a"
                href="/flyer.pdf"
                target="_blank "
                rel="noreferrer"
                className="block rounded-md px-3 py-2 text-base hover:bg-Westar-dark text-Eucalyptus hover:text-Eucalyptus-dark font-medium hover:font-semibold"
              >
                Sales
              </DisclosureButton>

              {/*  {links.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as={NavLink}
                  to={item.link}
                  className="block rounded-md px-3 py-2 text-base hover:bg-Westar-dark text-Eucalyptus hover:text-Eucalyptus-dark font-medium hover:font-semibold"
                >
                  {item.name}
                </DisclosureButton>
              ))} */}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}

const LogoLink = () => {
  return (
    <Link to="/">
      <div className="flex flex-row gap-2 items-end h-auto">
        <img className="h-11 w-auto" src={logo} alt="Pat's Farms" />
        <h1 className="w-full text-4xl tracking-wide font-Staatliches font-bold text-Eucalyptus">
          Pat's Farms
        </h1>
      </div>
    </Link>
  );
};
